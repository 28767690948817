<script>
export default {};
</script>

<template>
  <div>
    <!--start ABOUT US-->
    <section class="section bg-light" id="features">
      <div class="container">
        <div class="row vertical-content">
          <div class="col-lg-5">
            <div class="features-box">
              <h3>Sizi görüşmelerinize hazırlayacak: Mülakat Simülasyonu</h3>
              <p
                class="text-dark web-desc"
              >Planlanmış bir mülakatınız olsun veya olmasın insan kaynakları profesyonelleriyle mesleğinize yönelik yapılacak simülasyonlarla iş görüşmelerine her an hazır hissedeceksiniz.</p>
              
              <a href="/mulakat-simulasyonu" class="btn btn-custom margin-t-30 waves-effect waves-light">
                Daha Fazla
                <i class="mdi mdi-arrow-right"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-7">
            <div class="features-img features-right text-right">
              <img src="@/assets/images/online-world.svg" alt="macbook image" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--END ABOUT US-->
    <!--START WEBSITE-DESCRIPTION-->
    <section class="section section-lg bg-web-desc">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row">
          <div class="col-lg-12 text-center">
            <h2 class="text-white">Kariyerinizi planlarken gerekli ipuçları için</h2>
            <h2
              class="text-white"
            >Instagram adresimizi takip edin!</h2>
            <a v-bind:href="'https://www.instagram.com/cepteik/'" target="_blank" class="btn btn-custom margin-t-30 waves-effect waves-light mb-5 ">
              Şimdi Ziyaret Edin
              <i class="mdi mdi-arrow-right"></i>
            </a>
          </div>
        </div>
      </div>
    </section>

    <!--END WEBSITE-DESCRIPTION-->
  </div>
</template>