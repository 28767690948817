<script>
export default {};
</script>

<template>
  <div>
    <!--START TESTIMONIAL-->
    <section class="section pt-4" id="team">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2 margin-t-70">
            <h2 class="section-title text-center">Çözüm Ortaklarımız</h2>
            <div class="section-title-border margin-t-20"></div>
          </div>
        </div>
        <div class="row margin-t-50">
          <div class="col-lg-6 col-sm-6">
            <div class="team-box text-center hover-effect">
              <div class="team-wrapper">
                <div class="team-member">
                  <img alt src="@/assets/images/team/team-1.jpg" class="img-fluid rounded" />
                </div>
              </div>
              <h4 class="team-name">FD Eğitim Danışmanlık</h4>
              <p class="text-uppercase team-designation"></p>
            </div>
          </div>

          <div class="col-lg-6 col-sm-6">
            <div class="team-box text-center hover-effect">
              <div class="team-wrapper">
                <div class="team-member">
                  <a
                    href="http://www.inkay.org"
                    target="_blank"
                  >
                    <img alt src="@/assets/images/team/team-2.jpg" class="img-fluid rounded" />
                  </a>
                </div>
              </div>
              <h4 class="team-name" >İnsan Kaynakları Yöneticileri Derneği</h4>
              <p class="text-uppercase team-designation"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--END TESTIMONIAL-->
  </div>
</template>