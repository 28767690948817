<script>
import Navbar from "@/components/navbar";
import Services from "@/components/services";
import Features from "@/components/features";
import Partners from "@/components/partners";
import Testimonial from "@/components/testimonial";
import Contact from "@/components/contact";
import Footer from "@/components/footer";
import Process from "@/components/process";

/**
 * index-9 component
 */
export default {
  name: "index-9",
  components: {
    Navbar,
    Services,
    Features,
    Partners,
    Testimonial,
    Contact,
    Footer,
    Process,
  },
};
</script>

<template>
  <div>
    <Navbar />
    <div v-scroll-spy>
      <!--START HOME-->
      <section class="section bg-home height-100vh" id="home">
        <div class="bg-overlay"></div>
        <div class>
          <div class>
            <div class="container slidero">
              <div class="row">
                <div class="col-lg-8 offset-lg-2 text-white text-center">
                  <h1 class="home-title">Kariyerinizi Planlamak İçin CepteİK</h1>
                  <p
                    class="padding-t-15 home-desc"
                  ></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Particles -->
        <vue-particles class="position-relative" color="#FFF"></vue-particles>
      </section>
      <!--END HOME-->
      <Process />
      <Services />
      <Features />
      <Partners />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  </div>
</template>

<style scoped>
::v-deep .modal-content {
  background-color: #000000;
  color: white;
  background: none;
  border: none;
}
::v-deep .modal-header {
  border: none;
}
::v-deep .modal-backdrop {
  opacity: 0.5;
}
</style>