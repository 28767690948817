<script>
export default {};
</script>

<template>
  <!--START SERVICES-->
  <section class="section pt-5" id="services">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <h2 class="section-title text-center">SİZİN İÇİN NELER YAPIYORUZ?</h2>
          <div class="section-title-border margin-t-20"></div>
        </div>
      </div>

      <div class="row margin-t-30">
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <router-link to="/ne-yapiyoruz#ozgecmis" class="routerlink">
              <i class="pe-7s-news-paper text-custom"></i>
              <h4 class="padding-t-15">Profesyonel Özgeçmiş Düzenleme</h4>
              <p
                class="padding-t-15 text-dark"
              >Özgeçmiş, istediğiniz işe ulaşmanın ilk adımı olması açısından önem taşıyor. Mesleki geçmişinizi daha net ve doğru ifade edilmek için özgeçmişinizi, sizin de taleplerinizle revize ediyoruz.</p>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <router-link to="/ne-yapiyoruz#kariyer-planlamasi" class="routerlink">
              <i class="pe-7s-plane text-custom"></i>
              <h4 class="padding-t-15">Kariyer Planı Oluşturma</h4>
              <p
                class="padding-t-15 text-dark"
              >Mesleğinizde ilerlemeniz için yada yeni bir meslek edinme yolunda gerçekleştirmek istediğiniz hedefleriniz için yeteneklerinizi ve arzularınızı da göz önünde tutarak olabilecek en iyi yol haritasını çiziyoruz.</p>
            </router-link>
          </div>
        </div>
        <div class="col-lg-4 margin-t-20">
          <div class="services-box text-center hover-effect">
            <router-link to="/ne-yapiyoruz#linkedin-profil-danismanligi" class="routerlink">
              <i class="pe-7s-users text-custom"></i>
              <h4 class="padding-t-15">LinkedIn Profil Danışmanlığı</h4>
              <p
                class="padding-t-15 text-dark"
              >Günümüz dijital dünyasında var olabilmek ve iş dünyasını takip etmek için profilinizi rutin güncellemelerle geliştiriyor ve destekliyoruz.</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="row margin-t-30">
        <div class="col-lg-6 margin-t-20">
          <div class="services-box text-center hover-effect">
            <router-link to="/ne-yapiyoruz#mulakat-simulasyonu" class="routerlink">
              <i class="pe-7s-headphones text-custom"></i>
              <h4 class="padding-t-15">Sektör Bazlı Mülakat Simulasyonu</h4>
              <p
                class="padding-t-15 text-dark"
              >Çalıştığınız sektöre, mesleğinize, ilgi alanlarınıza yönelik simülasyonlar ile mülakat heyecanınızı yenmenizi, mülakata bir tanışma toplantısı gibi bakabilmenizi sağlıyoruz.</p>
            </router-link>
          </div>
        </div>

        <div class="col-lg-6 margin-t-20">
          <div class="services-box text-center hover-effect">
            <router-link to="/ne-yapiyoruz#egitim" class="routerlink">
              <i class="pe-7s-study text-custom"></i>
              <h4 class="padding-t-15">Kişisel ve Mesleki Eğitim</h4>
              <p
                class="padding-t-15 text-dark"
              >Kendinizi eksik hissettiğiniz, gelişmek istediğiniz, merak ettiğiniz konularla ilgili bireysel veya toplu eğitimler düzenliyoruz.</p>
            </router-link>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a v-bind:href="'/ne-yapiyoruz'" class="btn btn-custom margin-t-30 waves-effect waves-light mb-5 ">
          Daha Fazla
          <i class="mdi mdi-arrow-right"></i>
        </a>
      </div>      
    </div>
  </section>
  <!--START SERVICES-->
</template>